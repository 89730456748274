import React, {useRef} from 'react';

const ContactForm = () =>
{
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const formMess = document.querySelector(".formMessage");

        formMess.innerHTML = "<p class='error'>L'envoie de message n'est pas encore disponible.</p>";
    };

    return (
        <div className="form-container">
            <h2>Contactez nous</h2>
            <form ref={form} onSubmit={sendEmail} className="form-content">
                <label>Nom</label>
                <input type="text" name="name" required autoComplete="off" id="name" />
                <label>Email</label>
                <input type="email" name="email" required autoComplete="off" id="email"/>
                <label>Message</label>
                <textarea name="message" id="mess" />
                <input className="hover button" type="submit" value="Envoyer" />
            </form>
            <div className="formMessage"></div>
        </div>
    );
};

export default ContactForm;