export const projectsData = [
  {
    id: 1,
    title: "MeteorServer",
    date: "Septembre 2020",
    languages: ["Java", "MySQL 8.0"],
    infos:
      "High performance Java Server made from scratch. Bringing our unique vision to Habbo servers. Innovative wired system, level system, PvP, fully working pets, and much more.",
    img: "./assets/img/meteorserver-prev.png",
    link: "http://www.google.com",
  },
  {
    id: 2,
    title: "MeteorCMS",
    date: "Avril 2021",
    languages: ["HTML5", "CSS3", "JavaScript", "Java"],
    infos:
      "A modern, intuitive and elegant content management system made from scratch for Habboz. It was designed with a high focus on user experience while staying true to Habboz original thinking.",
    img: "./assets/img/meteorcms-prev.png",
    link: "http://www.google.com",
  },
  {
    id: 3,
    title: "MeteorClient",
    date: "Avril 2021",
    languages: ["HTML5", "Sass", "React", "TypeScript", "Nitro"],
    infos:
      "When Flash Player ended, the old habbo client died with it. Habboz has asked us to create the 1:1 replacement for it so they can use it. And that's what we did, using Nitro as its base.",
    img: "./assets/img/meteorclient-prev.png",
    link: "http://www.google.com",
  },
  {
    id: 4,
    title: "Habboz",
    date: "Decembre 2020",
    languages: ["MeteorServer", "MeteorCMS", "MeteorClient", "Lanna"],
    infos:
      "Habboz.ws is the result of what can be achieved when using all our technologies. It started with only few players in Decembre 2020 and it's now the most played french habbo server.",
    img: "./assets/img/meteorhz-prev.png",
    link: "http://www.google.com",
  },
];
