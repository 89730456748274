import React from 'react';
import Mouse from "../components/Mouse";
import Navigation from "../components/Navigation";
import Logo from "../components/Logo";
import ContactForm from "../components/ContactForm";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SocialNetwork from "../components/SocialNetwork";
import Buttons from "../components/Buttons";
import { motion } from "framer-motion";

const Contact = () =>
{
    const pageTransition = {
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: 200
        }
    };

    return (
        <main>
            <Mouse/>
            <motion.div initial="out" exit="out" animate="in" transition={{duration: 0.4}} variants={pageTransition} className="contact">
                <Navigation/>
                <Logo/>
                <ContactForm/>
                <div className="contact-infos">
                    <div className="address">
                        <div className="content">
                            <h4>adresse</h4>
                            <p>12 rue des étoiles</p>
                            <p>75001 Paris</p>
                        </div>
                    </div>
                        <div className="phone">
                            <div className="content">
                                <h4>téléphone</h4>
                                <CopyToClipboard text="0612345678" className="hover">
                                    <p style={{ cursor: "pointer" }} className="clipboard" onClick={() => alert("Téléphone copié !")}>06 23 45 65 78</p>
                                </CopyToClipboard>
                            </div>
                        </div>
                    <div className="email">
                        <div className="content">
                            <h4>email</h4>
                            <CopyToClipboard text="meteor@meteor.technology" className="hover">
                                <p style={{ cursor: "pointer" }} className="clipboard" onClick={() => alert("Email copié !")}>realmeteor1@gmail.com</p>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <SocialNetwork/>
                    <div className="credits">
                        <p>Meteor Technology - 2022</p>
                    </div>
                </div>
                <Buttons left={"/projet-4"} />
            </motion.div>
        </main>
    );
};

export default Contact;