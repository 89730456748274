import React, {useEffect} from 'react';

const DynamicText = () =>
{
    useEffect(() => {
        const target = document.getElementById("text-target");
        let array = ["modern", "creative", "innovative", "powerful", "ambitious"];
        let wordIndex = 0;
        let letterIndex = 0;

        const createLetter = () =>
        {
          const letter = document.createElement("span");
          target.appendChild(letter);

          letter.classList.add("letter");
          letter.style.opacity = "0";
          letter.style.animation = "anim 5s ease forwards";
          letter.textContent = array[wordIndex][letterIndex];

          setTimeout(() => {
              letter.remove();
          }, 2000);
        };

        const loop = () => {
          setTimeout(() => {
              if(wordIndex >= array.length){
                  wordIndex = 0;
                  letterIndex = 0;
              }
              if(letterIndex < array[wordIndex].length)
              {
                  createLetter();
                  letterIndex++;
                  loop();
              }
              else
              {
                  letterIndex = 0;
                  wordIndex++;
                  setTimeout(loop, 2000);
              }
          }, 80)
        };
        loop();
    }, []);

    return (
        <h2>
            <span className="dynamic-text">
            <span id="text-target"></span>
            </span>
            <span>TECHNOLOGY</span>
        </h2>

    );
};

export default DynamicText;